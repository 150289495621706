@layer base, mantine, components;

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap');

html,
body {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[id].section {
  padding-top: 6.25rem;
  margin-top: -6.25rem;
}

:target {
  padding-top: 6.25rem;
  margin-top: -6.25rem;
}
